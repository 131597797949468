<template>
  <PageWithLayout>
    <div class="section_comm">
      <div class="head_section">
        <h2 id="kakaoBody" class="tit_section">품목관리</h2>
      </div>
      <div class="body_section">
        <ItemListFilterBar
          ref="filterBar"
          :statusList="statusList"
          :defaultStatusId="defaultStatusId"
          :keywordDatas="keywordList"
          :defaultKeywordSelectedId="defaultKeywordSelectedId"
          :defaultKeywordInputText="defaultKeywordInputText"
          @onClickSearch="onClickSearch"
          @onClickExcelBtn="onClickExcelBtn"
        />

        <TableHead>
          <template v-slot:headLeft>
            <button
              v-if="isShowAccessBtn(menuId.ITEM_WRITE)"
              type="button"
              class="btn_tertiary btn_medium"
              @click="onClickGoToWrite"
            >
              <span class="ico_purchase ico_cross_w" />신규 등록
            </button>
          </template>
          <template v-slot:headRight>
            <template v-if="$isAdmin">
              <button class="btn_primary btn_small" @click="onClickStatusUsable">사용중</button>
              <button class="btn_fourthly btn_small" @click="onClickStatusUnusable">
                사용중지
              </button>
            </template>

            <SelectBoxPageRange
              :currentMaxRowCount.sync="size"
              @update:currentMaxRowCount="onChangeRowCount"
            />
          </template>
        </TableHead>
        <ListPage
          :list="dataList"
          :currentPageIndex="currentPageIndex"
          :totalPageCount="totalPageCount"
          :maxRowCount="size"
          :maxPaginationCount="maxPaginationCount"
          @onChangePage="onChangePage"
        >
          <template v-slot:colgroup>
            <!-- 체크박스 -->
            <col style="width: 40px" />
            <!-- 품목코드 -->
            <col style="width: 120px" />
            <!-- 분류 -->
            <col style="width: 250px" />
            <!-- 품목명 -->
            <col style="" />
            <!-- 단위 -->
            <col style="width: 120px" />
            <!-- 규격 -->
            <col style="width: 200px" />
            <!-- 제조사 -->
            <col style="width: 200px" />
            <!-- 등록일 -->
            <col style="width: 120px" />
            <!-- 상태 -->
            <col style="width: 120px" />
          </template>
          <template v-slot:tr>
            <th :data-ellip="$isAdmin ? '상태가 대기,사용중,사용중지 일때에 활성화됩니다.' : ''">
              <CheckboxItem
                v-if="$isAdmin"
                ref="checkAll"
                :isDisabled="!computedDisabeldAllCheckBox"
                @update:checkedNames="onClickedAll"
              />
            </th>
            <th>품목코드</th>
            <th>분류</th>
            <th>품목명</th>
            <th>단위</th>
            <th>규격</th>
            <!-- <th>입고처</th> -->
            <!-- <th>보증기간</th> -->
            <th>제조사</th>
            <th>등록일</th>
            <th>상태</th>
          </template>
          <template v-slot:row="slotProps">
            <ItemListLine :rowData="slotProps.rowData" :checkedNames.sync="checkedNames" />
          </template>
          <template v-slot:emptyList>
            <tr>
              <td colspan="9" class="td_empty">품목이 없습니다</td>
            </tr>
          </template>
        </ListPage>
      </div>
    </div>
  </PageWithLayout>
</template>

<script>
import { mapState } from "vuex";
import PageWithLayout from "@/components/layout/PageWithLayout";
import ItemListFilterBar from "@/components/admin/item/list/ItemListFilterBar";
import ItemListLine from "@/components/admin/item/list/ItemListLine";
import TableHead from "@/components/shared/TableHead";
import SelectBoxPageRange from "@/components/common/selectBox/SelectBoxPageRange";
import ListPage from "@/components/shared/ListPage";
import CheckboxItem from "@/components/common/checkbox/CheckboxItem";

import ApiService from "@/services/ApiService";
import PageMixin from "@/mixins/PageMixin";
import ItemListMixin from "@/mixins/item/ItemListMixin";
import { ITEM_LIST_ACTION } from "@/store/modules/item/action";
import LocalStorageManager from "@/LocalStorageManager";
import { PROGRESS_START_ACTION, PROGRESS_END_ACTION } from "@/store/modules/progressbar/action";
import { downloadFile } from "@/utils/fileUtils";
import { makeQueryStringByObject } from "@/utils/urlUtils";

import { ALL } from "@/constants/dates";
import { ALERT_ACTION } from "@/store/modules/alert/action";

export default {
  name: "ItemList",
  components: {
    PageWithLayout,
    TableHead,
    SelectBoxPageRange,
    ListPage,
    ItemListFilterBar,
    ItemListLine,
    CheckboxItem,
  },
  mixins: [PageMixin, ItemListMixin],
  data() {
    return {
      defaultStatusId: "",
      defaultKeywordSelectedId: "itemName",
      defaultKeywordInputText: "",

      checkedNames: [],
      checkedIdArr: [],
      checkedStatus: "",
    };
  },
  computed: {
    ...mapState({
      statusList: (state) => state.item.statusList,
      keywordList: (state) => state.item.keywordList,
    }),
    computedDisabeldAllCheckBox() {
      const { status } = this.$route.query;
      const ret = status === "R" || status === "C" || status === "S";

      return ret;
    },
  },
  watch: {
    checkedNames(newVal, oldVal) {
      if (!this.$refs.checkAll) {
        return;
      }
      if (this.dataList.length === 0 || this.dataList.length !== newVal.length) {
        this.$refs.checkAll.forceUnChecked();
      } else {
        this.$refs.checkAll.forceChecked();
      }
    },
  },
  created() {
    this.$store.dispatch(ITEM_LIST_ACTION);
  },
  methods: {
    onClickedAll(bSelect) {
      if (bSelect) {
        this.checkedNames = this.dataList;
      } else {
        this.checkedNames = [];
      }
    },
    onClickGoToWrite() {
      this.goWrite();
    },
    async onClickStatusUsable() {
      this.makeCheckedIdArr();
      if (this.checkedIdArr.length == 0) {
        this.alert("품목을 선택해주세요.");
        return;
      }
      const obj = this.changeItemStatusParam(this.checkedIdArr, "C");
      this.$store.dispatch(ALERT_ACTION.SHOW_ALERT_YN, {
        text: `총 ${this.checkedIdArr.length}개의 품목을<br />사용중 상태로 변경하시겠습니까?`,
        onClickY: () => {
          this.submitChangeItemStatus(obj);
        },
      });
    },
    async onClickStatusUnusable() {
      this.makeCheckedIdArr();
      if (this.checkedIdArr.length == 0) {
        this.alert("품목을 선택해주세요.");
        return;
      }
      const obj = this.changeItemStatusParam(this.checkedIdArr, "S");
      this.$store.dispatch(ALERT_ACTION.SHOW_ALERT_YN, {
        text: `총 ${this.checkedIdArr.length}개의 품목을<br />사용중지 상태로 변경하시겠습니까?`,
        onClickY: () => {
          this.submitChangeItemStatus(obj);
        },
      });
    },
    async submitChangeItemStatus(obj) {
      const path = `${this.$apiPath.ITEM}`;
      const result = await ApiService.shared.putData(path, obj);
      const { code, data, text } = result;
      if (code !== "200") {
        this.checkedIdArr = [];
        this.alert(text);
        return;
      }
      this.checkedIdArr = [];
      window.location.reload();
    },
    async goWrite() {
      this.$router.push({ path: `${this.$routerPath.ITEM_WRITE}` });
    },
    makeCheckedIdArr() {
      this.checkedNames.forEach((item) => {
        this.checkedIdArr.push(item.itemCode);
      });
    },
    async onClickExcelBtn() {
      this.$store.dispatch(PROGRESS_START_ACTION);
      const path = `${this.$apiPath.EXCEL}/item`;
      const filterBar = this.$refs.filterBar;
      if (!filterBar) return;
      const objFilterBarData = filterBar.getCommonFilterbarData();
      const params = this.getFilterbarParams(objFilterBarData, true);
      const result = await ApiService.shared.get(`${path}${makeQueryStringByObject(params)}`, {
        isNeedAuth: true,
        responseType: "arraybuffer",
      });

      this.$store.dispatch(PROGRESS_END_ACTION);

      downloadFile(result, `품목관리_${this.moment().format("YYYYMMDD")}.xlsx`);
    },
  },
};
</script>
