<template>
  <ListRow>
    <td>
      <div class="box_choice type_alone">
        <div class="item_choice">
          <CheckboxItem
            v-if="$isAdmin"
            :id="rowData"
            :isDisabled="computedDisabledCheckBox"
            :checkedNames.sync="checkedNamesSync"
          />
          <label class="lab_choice">
            <span class="ico_account ico_check"></span>
          </label>
        </div>
      </div>
    </td>
    <td>{{ rowData.displayCode }}</td>
    <td class="align_left">{{ category }}</td>
    <td class="td_ellip" :data-ellip="rowData.itemName">
      <!-- 상태값이 고정일 경우 link_point 클레스 추가 -->
      <router-link
        :to="toPath"
        :class="['link_subject']"
        :data-ellip="rowData.itemName"
        v-html="rowData.itemName"
      />
    </td>
    <td>{{ rowData.unitValue }}</td>
    <td>{{ rowData.standard }}</td>
    <!-- <td>{{ rowData.store | nullToDash }}</td>
    <td class="align_right">
      {{ rowData.warranty > 0 ? `${rowData.warranty} 개월` : '-' }}
    </td> -->
    <td class="td_ellip" :data-ellip="rowData.manufacturer">
      {{ rowData.manufacturer | nullToDash }}
    </td>
    <td>{{ rowData.regDate | dateStringFormat }}</td>
    <td>
      <span :class="['badge_state', badgeStateClassName]">
        {{ rowData.statusName }}
      </span>
    </td>
  </ListRow>
</template>

<script>
import ListRow from "@/components/shared/ListRow";
import ListLineMixin from "@/mixins/ListLineMixin";
import { makePathWithQuery } from "@/utils/urlUtils";
import CheckboxItem from "@/components/common/checkbox/CheckboxItem";

export default {
  name: "ItemListLine",
  components: {
    ListRow,
    CheckboxItem,
  },
  mixins: [ListLineMixin],
  props: {
    checkedNames: Array,
    rowData: {
      id: String,
      title: Number,
    },
  },
  computed: {
    toPath() {
      const { itemCode } = this.rowData;
      const path = this.$routerPath.ITEM_VIEW;
      const ret = makePathWithQuery(path, itemCode, this.$route.query);

      return ret;
    },
    isSelected() {
      const ret = this.checkedNames.find((item) => String(item) === String(this.rowData.id));

      return !!ret;
    },
    checkedNamesSync: {
      get() {
        return this.checkedNames;
      },
      set(value) {
        this.$emit("update:checkedNames", value);
      },
    },
    computedDisabledCheckBox() {
      const { status } = this.rowData;
      let ret = false;
      if (status === "R" || status === "C" || status === "S") {
        ret = false;
      } else {
        ret = true;
      }
      return ret;
    },

    badgeStateClassName() {
      const status = this.rowData.status;
      switch (status) {
        case "R":
          return "";
        case "C":
          return "on";
        case "S":
          return "disabled";
      }
    },
  },
};
</script>
