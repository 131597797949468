import { mapState } from "vuex";
import ListPageMixin from "@/mixins/ListPageMixin";

const ItemListMixin = {
  mixins: [ListPageMixin],
  data() {
    return {
      API_PATH: this.$apiPath.ITEM,
    };
  },
  created() {
    const params = this.checkUrlQuery();
    this.getDataInit(params);
  },
  methods: {
    changeItemStatusParam(list, status) {
      return {
        itemCodeList: list,
        status: status,
      };
    },
    checkUrlQuery() {
      const { query } = this.$route;

      const objPaging = this.checkQueryPaging(query);

      const objKeyword = this.checkQueryKeywords(query, this.keywordList);

      const objDates = this.checkQueryDates(query, "startDt", "endDt");

      const objStatus = this.checkQueryStatus(query);

      const objCateNum = this.checkQueryCateNum(query);

      return Object.assign({}, objPaging, objKeyword, objDates, objStatus, objCateNum);
    },
    getFilterbarParams(objData) {
      const {
        status,
        startDt,
        endDt,
        category0,
        category1,
        category2,
        keywordSelectedId,
        keywordInputText,
      } = objData;

      let params = {};

      if (status) params.status = status;

      const cateNum = category2 || category1 || category0;
      if (cateNum) params.cateNum = cateNum;

      if (startDt && endDt) {
        params["startDt"] = startDt;
        params["endDt"] = endDt;
      }

      if (keywordSelectedId && keywordInputText) {
        params[keywordSelectedId] = keywordInputText;
      }

      return params;
    },
    checkQueryKeywords(query, arrKeyword) {
      let ret = {};
      const itemKeyword = arrKeyword.find((item) => query[item.id]);
      if (!itemKeyword) return ret;
      const keywordId = itemKeyword.id;
      ret[keywordId] = query[keywordId];

      this.defaultKeywordSelectedId = keywordId;
      this.defaultKeywordInputText = query[keywordId];
      return ret;
    },
    checkQueryStatus(query) {
      const { status } = query;
      if (!status) {
        return;
      }

      return { status: status };
    },
  },
};

export default ItemListMixin;
