<template>
  <FilterBarBase
    :excelBtn="$isAdmin ? true : false"
    @onClickSearch="onClickSearch"
    @onClickReset="onClickReset"
    @onClickExcelBtn="onClickExcelBtn"
  >
    <dl class="w152">
      <dt>상태</dt>
      <dd>
        <SelectBox class="w152" :dataList="statusList" :value.sync="statusId" />
      </dd>
    </dl>
    <dl>
      <dt>등록일자</dt>
      <dd>
        <DateRangeQuickSelect :dates.sync="dates" />
      </dd>
      <dt>분류</dt>
      <dd>
        <CategoryGroupIncludeData
          :category0.sync="category0"
          :category1.sync="category1"
          :category2.sync="category2"
        />
      </dd>
      <dt>검색어</dt>
      <dd>
        <div class="group_form">
          <SelectBox class="w208" :dataList="keywordDatas" :value.sync="keywordSelectedId" />
          <Input
            style="width: 423px"
            type="text"
            :value.sync="keywordInputText"
            @onEnterKey="onClickSearch"
          />
        </div>
      </dd>
    </dl>
  </FilterBarBase>
</template>

<script>
import FilterBarBase from "@/components/shared/filterBar/FilterBarBase";
import Input from "@/components/common/input/Input";
import Radio from "@/components/common/radio/Radio";
import SelectBox from "@/components/common/selectBox/SelectBox";
import DateRangeQuickSelect from "@/components/common/calendar/DateRangeQuickSelect";
import CategoryGroupIncludeData from "@/components/common/selectBox/CategoryGroupIncludeData";

import FilterBarMixin from "@/mixins/FilterBarMixin";

import { dateToString } from "@/utils/dateUtils";

export default {
  name: "ItemListFilterBar",
  components: {
    FilterBarBase,
    Input,
    Radio,
    SelectBox,
    DateRangeQuickSelect,
    CategoryGroupIncludeData,
  },
  mixins: [FilterBarMixin],
  props: {},
  data() {
    return {};
  },
  methods: {
    onClickSearch() {
      let obj = this.getCommonFilterbarData();
      this.$emit("onClickSearch", obj);
    },
    onClickReset() {
      this.onClickResetFilterBar();
    },
    onClickResetFilterBar() {
      this.statusId = this.defaultStatusId;
      this.keywordSelectedId = this.defaultKeywordSelectedId;
      this.keywordInputText = "";
      this.dates = [];
      this.category0 = null;
      this.category1 = null;
      this.category2 = null;
    },
    onClickExcelBtn() {
      const isDatesSelected = this.dates && this.dates.length === 2;

      this.$emit("onClickExcelBtn", isDatesSelected);
    },
  },
};
</script>
